<template>
  <lf-typeahead
    v-bind="attrs"
    :model-value="innerValue"
    :custom-search="true"
    :suggestions="addressSuggestions"
    :map-suggestion="mapAddressSuggestion"
    :loading="isSearchingAddresses"
    suggestion-class="overflow-x-hidden text-ellipsis"
    name="Address Typeahead"
    @select="handleAddressSelect"
    @update:modelValue="handleAddressInput"
  >
    <template #suggestion="{ suggestion }">
      <icon-base icon="location" class="mr-2" />
      {{ mapAddressSuggestion(suggestion) }}
    </template>
  </lf-typeahead>
</template>

<script setup lang="ts">
import { useAttrs, ref, watch } from "vue";
import type { IAddress, ISmartyStreetsSuggestion } from "@/models/common";
import externalApiService from "@/services/external";
import debounce from "lodash/debounce";
import LfTypeahead from "@/components/ui/inputs/LfTypeahead.vue";

const attrs = useAttrs();

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: string): void;
  (e: "select", address: Partial<IAddress>): void;
}>();

const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  }
});

const innerValue = ref<string>(props.modelValue);
const addressSuggestions = ref<ISmartyStreetsSuggestion[]>([]);
const isSearchingAddresses = ref(false);

const mapAddressSuggestion = (suggestion: ISmartyStreetsSuggestion) => {
  const { street_line, entries, city, state, zipcode } = suggestion;
  let { secondary = "" } = suggestion;

  if (secondary) {
    secondary = ` ${secondary}`;

    if (entries > 1) {
      secondary += ` (${entries} entries)`;
    }
  }

  return `${street_line}${secondary} ${city}, ${state} ${zipcode}`;
};

const handleAddressInput = debounce(async (value: string) => {
  innerValue.value = value;
  emit("update:modelValue", value || "");

  if (!value) {
    return;
  }

  isSearchingAddresses.value = true;

  try {
    const { data } = await externalApiService.smartyStreetsRequest(value);
    addressSuggestions.value = data.suggestions;
  } catch (error) {
    // Address suggestions should not be critical (just a helpful feature to aid in filling out an address form),
    // so this can fail silently
    addressSuggestions.value = [];
  }

  isSearchingAddresses.value = false;
}, 300);

const selectAddressSuggestion = async (address: ISmartyStreetsSuggestion) => {
  innerValue.value = mapAddressSuggestion({ ...address, entries: 0 });

  emit("select", {
    address_line: address.street_line,
    address_line2: address.secondary,
    city: address.city,
    // Eventually, this could be international, but for now address search is US only.
    country: "US",
    state: address.state,
    zip: address.zipcode
  });
};

/**
 * Given an address suggestion with multple entries (i.e. apartment numbers),
 * sets the list of suggestions to all possible matches
 */
const expandAddressSuggestion = async (address: ISmartyStreetsSuggestion) => {
  isSearchingAddresses.value = true;

  try {
    const { data } =
      await externalApiService.expandSmartyStreetsSuggestion(address);
    addressSuggestions.value = data.suggestions;
  } catch (error) {
    // If the request to expand the suggestion fails, we can at least fill in the details we do have
    selectAddressSuggestion({
      ...address,
      entries: 0,
      secondary: ""
    });
  }

  isSearchingAddresses.value = false;
};

const handleAddressSelect = async (address: ISmartyStreetsSuggestion) => {
  // If an address has multiple entries (i.e. apartments), try to get all possible values
  if (address.entries > 1) {
    expandAddressSuggestion(address);
    return;
  }

  selectAddressSuggestion(address);
};

watch(
  () => props.modelValue,
  (modelValue) => {
    innerValue.value = modelValue;
  }
);
</script>
